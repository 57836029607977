*,body,h1,h2,h3,h4,h5,h6{font-family: "Poppins",sans-serif !important;}

#header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {
  color: #13852F;
}
.d-sm-none{
  display: block !important;
}
.d-lg-none{
  display: none !important;
}
.btn-gradient:not(.btn-outline) {
  background: linear-gradient(298deg, #7FB800 0%,#13852F 80%);
  color: #FFF !important;
}

.btn-gradient:hover, .btn-info:hover {
  background: linear-gradient(118deg, #7FB800 0%,#13852F 80%) !important;
  color: #FFF;
}
.bg-green {
  background: linear-gradient(339deg, #13852F 0%,#0F9B0F 100%);
  color: #FFF;
}
.btn-info {
  background: #FFC300;
  color: #FFF;
}
.text-green{
  color: #439A35;
}
.text-mint{
  color: #E2F6B9 !important;
}
.custom-header-bar:before{
  content: unset;
}
.fa-sustain:before {
  content: url("assets/img/control/sustainability.svg");
}
.fa-impact:before {
  content: url("assets/img/control/impact.svg");
}
.fa-modern:before {
  content: url("assets/img/control/modern.svg");
}
.fa-preliminary:before {
  content: url("assets/img/control/preliminary.svg");
}
.fa-market:before {
  content: url("assets/img/control/market.svg");
}
.fa-agroforestry:before {
  content: url("assets/img/control/agroforestry.svg");
}
.fa-broilers:before {
  content: url("assets/img/control/broilers.svg");
}
.fa-abbatoir:before {
  content: url("assets/img/control/abbatoir.svg");
}
.fa-general:before {
  content: url("assets/img/control/general.svg");
}
.fa-bars:before {
  content: url("assets/img/control/bars.svg");
}
.fa-check1:before {
  content: url("assets/img/control/check-1.svg");
}
.fa-check2:before {
  content: url("assets/img/control/check-2.svg");
}

.icon-bagg{
  background: #ffffff url(assets/img/control/icon-bg.svg) no-repeat right top !important;
}
.make-upper{
  margin-top: 100px !important;
}
.upper{
  margin-top: -200px
}
.check{
  margin-left: -60px;margin-right: 20px;
}
.border-radius {
  border-radius: 10px !important;
}
html .text-color-dark, html .text-dark {
  color: #443737 !important;
}
.box-shadow{
  box-shadow: 0px 0px 15px #7FB8001A;
}
.border-all-green {
  border: 1px solid rgba(67, 154, 53, 1) !important;
  box-shadow: 0px 0px 15px #00000029;
  /*border: 1px solid #439A35;*/
  border-radius: 10px;
  opacity: 1;
}
.set{
  vertical-align: middle; position: relative; top: -30px;
}


@media (max-width: 991px) {
  #header .header-btn-collapse-nav {
    background: unset;
  }
  .d-sm-none{
    display: none !important;
  }

  .d-lg-none{
    display: block !important;
  }
  /*Index*/
  .col-sm-4{
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 0px;
  }
  .text-6{
    font-size: 9px !important;
  }
  .text-8, .text-12{
    font-size: 23px !important;
  }
  .text-14{
    font-size: 39px !important;
  }
  .farm{
    margin-top: -100px;
    z-index: 1;
  }
  .fa-sustain{
    content: url("assets/img/control/icon-sustain-mobile.svg");
  }
  .fa-impact{
    content: url("assets/img/control/icon-impact.svg");
  }
  .fa-modern{
    content: url("assets/img/control/icon-modern-mobile.svg");
  }
  .fa-facebook{
    content: url("assets/img/control/icon-modern-mobile.svg");
  }
  .icon-bagg{
    background: #ffffff url(assets/img/control/icon-bg-mobile.svg) no-repeat right top !important;
  }
  .upper{
    margin-top: -1000px
  }
  .make-upper{
    margin-top: 900px !important;
  }
  .set{
    vertical-align: middle; position: relative; top: 30%;
  }
  .check {
    margin-left: -50px;
    margin-right: 10px;
  }
  .title::before {
    background: #FBAD1B none repeat scroll 0 0;
    top: 75px !important;
    content: "";
    height: 8px;
    position: absolute;
    right: 80px;
    width: 150px;
    z-index: 100;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #439A3580;
  }
  .owl-theme .owl-dots .owl-dot span{
    background: #439A3580;
  }

  .owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
    background-color: #13852F;
  }

}

