/*!* Add here all your CSS customizations *!*/
/**,body,h1,h2,h3,h4,h5,h6{font-family: "Poppins",sans-serif !important;}*/

/*.text-dark{*/
/*  color: #2C272E !important*/
/*}*/

/*hr {*/
/*  border-color: #313131;*/
/*  border-top: 1.5px solid #313131;*/
/*  background: unset;*/
/*}*/
/*.fa-bars:before {*/
/*  content: url("../img/control/bars.svg") !important;*/
/*}*/
/*.fa-arrow:before {*/
/*  content: url("../img/control/arrow.svg") !important;*/
/*}*/
/*.btn.header-btn-collapse-nav .fa.bars{*/
/*  content: url("../img/control/bars.svg");*/
/*}*/
/*.icon.e-learning{*/
/*  content: url("../img/control/e-learning.svg");*/
/*  height: 30px;*/
/*}*/
/*.icon.best-experts{*/
/*  content: url("../img/control/best-experts.svg");*/
/*  height: 30px;*/
/*}*/
/*.icon.structured-schedule{*/
/*  content: url("../img/control/structured-schedule.svg");*/
/*  height: 30px;*/
/*}*/
/*.icon.visit{*/
/*  content: url("../img/control/visit-icon.svg");*/
/*  height: 100px;*/
/*}*/
/*.icon.call{*/
/*  content: url("../img/control/call-icon.svg");*/
/*  height: 100px;*/
/*}*/
/*.icon.email{*/
/*  content: url("../img/control/email-icon.svg");*/
/*  height: 100px;*/
/*}*/
/*#header .header-btn-collapse-nav {*/
/*  background: unset;*/
/*}*/

/*.line {*/
/*  background-color: transparent !important;*/
/*  border-radius: 0px !important;*/
/*  !*border-bottom-color: #65589C;*!*/
/*  border-bottom: 2px solid #65589C;*/
/*  border-top: none !important;*/
/*  border-left: none !important;*/
/*  border-right: none !important;*/
/*  padding-left: 0px !important;*/
/*  margin-bottom: 20px;*/
/*}*/
/*.form-control:focus{*/
/*  box-shadow: unset;*/
/*}*/

/*html .bg-blue {*/
/*  background-color: #1AA3BA !important;*/
/*  color: #ffffff;*/
/*}*/
/*html .bg-cream{*/
/*  background-color: #FDF0E9 !important;*/
/*}*/
/*html .bg-deep-cream {*/
/*  background-color: #FDF0E9 !important;*/
/*  color: #333333;*/
/*}*/

/*html .bg-color-white {*/
/*  background-color: #FFFFFF !important;*/
/*  box-shadow: 0px 0px 60px #9B9B9B1C;*/
/*}*/
/*html .bg-color-pink {*/
/*  background-color: #FDF0E9 !important;*/
/*  box-shadow: 0px 0px 60px #9B9B9B1C;*/
/*  border-radius: 18px;*/
/*}*/
/*html .bg-color-yellow {*/
/*  background-color: #FFC82A !important;*/
/*  box-shadow: 0px 0px 60px #9B9B9B1C;*/
/*  border-radius: 8px;*/
/*}*/
/*html .bg-color-blue {*/
/*  background-color: #1AA3BA !important;*/
/*  box-shadow: 0px 0px 60px #9B9B9B1C;*/
/*  color: #ffffff;*/
/*  border-radius: 8px;*/
/*}*/


/*.footer div h5{*/
/*  font-size: 20px !important;*/
/*  font-weight: bold;*/
/*  color: #fff;*/

/*}*/
/*.footer div p, .footer div p .footer-links{*/
/*  font-size: 15px ;*/
/*  color: #fff !important;*/
/*}*/
/*.sign{*/
/*  padding-bottom: 40px !important;*/
/*}*/
/*!*here index css*!*/

/* .text-blue{*/
/*   color: #65589C !important;*/
/* }*/
/*.caption h1, .caption p{*/
/*  color: #ffffff !important;*/
/*  text-align: left;*/
/*}*/
/*.caption h1 span{*/
/*  font-weight: lighter;*/
/*}*/


/*.download .row.align-items-center div h2{*/
/*  color: #65589C;*/
/*}*/
/*#header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {*/
/*  color: #FFFFFF;*/
/*  font-size: 16px;*/
/*  font-weight: bold;*/
/*}*/

/*html .bg-color-dark-scale-2.rounded{*/
/*  background-color: #FEFEFE !important;*/
/*  padding: 40px !important;*/
/*  box-shadow: 0px 10px 45px #0000001A !important;*/

/*  border-radius: 20px !important;*/
/*}*/
/*.bg-color-dark-scale-2.box h4{*/
/*  color: #65589C !important;*/
/*  font-size: 35px !important;*/
/*  margin-bottom: 20px !important;*/
/*}*/
/*.bg-color-dark-scale-2.box p{*/
/*  color: #000000 !important;*/
/*  font-size: 23px !important;*/
/*}*/

/*.process-step-content h4{*/
/*  color: #65589C;*/
/*}*/
/*.process-step-content p{*/
/*  font-size: 28px;*/
/*}*/

/*.toggle > label:hover {*/
/*  background: #FFC12D;*/
/*}*/


/*.news{*/
/*  !*border-radius: 10px; *!*/
/*  !*padding: 47px;*!*/
/*}*/
/*#support{*/
/*  overflow: visible;*/
/*}*/
/*.bitmap{*/
/*  position:absolute; top: 35px; right:100px;*/
/*}*/
/*.down div h2{*/
/*  color: #141414F2 !important; font-size: 60px; line-height: 1;*/
/*}*/
/*.down div p{*/
/*  color: #141414F2 !important; font-size: 22px;*/
/*}*/

/*#footer {*/
/*  background: #FFFFFF !important;*/
/*}*/

/*.image-box h4{*/
/*  color: white !important;*/
/*}*/

/*.footer div h5{*/
/*  font-size: 20px !important;*/
/*  font-weight: bold;*/
/*  color: #707070;*/

/*}*/
/*#footer h5{*/
/*  color: #707070;*/
/*}*/
/*.footer div p, .footer div p .footer-links{*/
/*  font-size: 20px ;*/
/*  color: #707070 !important;*/
/*}*/
/*.d-sm-none{*/
/*  display: block !important;*/
/*}*/
/*.d-lg-none{*/
/*  display: none !important;*/
/*}*/



/*html, html .bg-yellow {*/
/*  background-color: #FEFBF3 !important;*/

/*}*/
/*!*html, html *!*/
/*.bg-yelloww {*/
/*  background-color: #FFC82A !important;*/

/*}*/
/*.title{*/
/*  color: #FFFFFF !important;*/
/*}*/


/*.dropdown-item {*/
/*  padding: 0px !important*/
/*}*/
/*.dropdown:hover .dropdown-item{*/
/*  border-bottom: 1px solid #FFC82A;*/
/*  color: #ffffff !important;*/
/*}*/
/*.footer div div div p:hover .footer-links {*/
/*  border-bottom: 2px solid #FFC12D;*/
/*}*/
/*html .bg-blue{*/
/*  background-color: #1AA3BA !important;*/
/*}*/
/*section.section-blue {*/
/*  background-color: #1BA3BA !important;*/
/*  border-color: #1BA3BA !important;*/
/*  background-image: unset  !important;*/
/*}*/
/*.post-content {*/
/*  padding: 5px 15px;*/
/*  min-height: 30px;*/
/*}*/

/*article{*/
/*  border-radius: 8px;*/
/*  overflow: hidden;*/
/*}*/
/*.sc-icon{*/
/*  position: absolute; left: 35px; top: 22px; z-index: 200*/
/*}*/
/*.img-thumbnail{*/
/*  width: 100%;*/
/*  !*max-height: 252px;*!*/
/*  height: auto;*/
/*}*/
/*.post-image{*/
/*  max-height: 252px;*/
/*  overflow: hidden;*/
/*}*/
/*.post-content.white, .post-content.white p, .post-content.white h2 a{*/
/*  background-color: #F7F8F9 !important;*/
/*  color: #9FA3A7 !important;*/
/*}*/
/*.post-content.blue, .post-content.blue p, .post-content.blue h2 a{*/
/*  background-color: #1AA3BA !important;*/
/*  color: #FFFFFF !important;*/
/*  font-weight: normal;*/
/*}*/
/*.post-content.yellow, .post-content.yellow p, .post-content.yellow h2 a{*/
/*  background-color: #FFC82A !important;*/
/*  color: #FFFFFF !important;*/
/*  font-weight: normal;*/
/*}*/
/*.events{*/
/*  background-image: url(../img/control/events-bg.svg) !important;*/
/*  background-color: #FFC82A !important;*/
/*  background-size: cover !important;*/
/*  background-repeat: no-repeat !important;*/
/*  background-position: bottom !important;*/
/*  animation-duration: 750ms !important;*/
/*  animation-delay: 300ms !important;*/
/*  animation-fill-mode: forwards !important;*/
/*  height: auto;*/
/*}*/
/*.events p{*/
/*  font-size: 16px;*/
/*}*/
/*.text-blue{*/
/*  color: #1AA3BA !important;*/
/*}*/
/*.testimonial.testimonial-style-2 .testimonial-author img {*/
/*  margin: 0;*/
/*  max-width: 170px;*/
/*  max-height: 170px;*/
/*}*/
/*.testimonial.testimonial-style-2 {*/
/*  text-align: left;*/
/*}*/
/*html .bg-orange {*/
/*  background-color: #F89521 !important;*/
/*  color: #ffffff;*/
/*}*/
/*html .bg-blue {*/
/*  background-color: #1AA3BA !important;*/
/*  color: #ffffff;*/
/*}*/
/*section.section {*/
/*  padding: 0px 0;*/
/*}*/

/*.btn-orange{*/
/*  background-color: #FFC82A !important;*/
/*  color: #fff !important;*/
/*  border-color: #FFC82A !important;*/
/*}*/

/*.btn-yellow{*/
/*  background-color: #FFC82A;*/
/*}*/
/*.text-white {*/
/*  color: #ffffff !important;*/
/*}*/
/*.testi .owl-carousel .owl-nav {*/
/*  bottom: -10%;*/
/*  top: unset;*/
/*  position: absolute;*/
/*  width: 15%;*/
/*  right: 40%;*/
/*  margin-top: 0;*/
/*  transform: translate3d(0, -50%, 0);*/
/*}*/
/*.testi.testicool .owl-carousel .owl-nav{*/
/*  display: none !important;*/
/*}*/
/*.testi .owl-carousel .owl-nav button.owl-prev {*/
/*  left: 10;*/
/*}*/
/*.testi .owl-theme .owl-nav [class*=owl-] {*/
/*  border-radius: 50%;*/
/*  background-color: #ED3426 !important;*/
/*  color: #ffffff;*/
/*}*/
/*.facility .owl-carousel .owl-dots.disabled,.facility .owl-carousel .owl-nav.disabled {*/
/*  display: block;*/
/*}*/
/*.facility .owl-carousel .owl-nav {*/
/*  top: unset;*/
/*  bottom: 15px;*/
/*  left: 42%;*/
/*  width: 15%;*/
/*}*/
/*.facility .owl-carousel .owl-nav button[class*="owl-"] {*/
/*  background-color: unset;*/
/*  border-color: unset;*/
/*  color: #FFC82A;*/
/*}*/

/*.facility .owl-carousel .owl-nav button.owl-prev:before,*/
/*.facility .owl-carousel .owl-nav button.owl-next:before {*/
/*  font-weight: 900;*/
/*  font-size: 1rem;*/
/*}*/
/*.testimonial.testimonial-style-2{*/
/*  padding: 50px;*/
/*}*/
/*.btn-blue {*/
/*  background-color: #1AA3BA;*/
/*}*/



/*footer a{*/
/*  color: #333333;*/
/*}*/
/*footer h5{*/
/*  color: #1AA3BA;*/
/*  font-size: 18px !important;*/
/*}*/
/*footer  h5.text-4.mb-3{*/
/*  font-size: 18px !important;*/
/*}*/
/*footer  p{*/
/*  font-size: 16px !important;*/

/*}*/

/*html section.section-light {*/
/*  background-color: #f7f7f7 !important;*/
/*  border-color: #f7f7f7 !important;*/
/*}*/
/*html section.section-light p {*/
/*  color: #33333380;*/
/*}*/
/*hr {*/
/*  border-color: #33333380;*/
/*  border-top: 1.5px solid #33333380;*/
/*  background: unset;*/
/*}*/
/*.flexy{*/
/*  position: absolute;*/
/*  bottom: 10%;*/
/*}*/
/*.flexy p strong{*/
/*  font-size: 14px !important;*/
/*}*/
/*.flexy p{*/
/*  font-size: 12px !important;*/
/*}*/
/*.testimonial p{*/
/*  color: #0F0F0F;*/
/*}*/
/*.fa-arrow.ml-5{*/
/*  display: inline !important;*/
/*}*/
/*.nav .dropdown{*/
/*  cursor: pointer !important;*/
/*}*/

/*.box-content.mission, .box-content.vision{*/
/*  padding: 0 !important;*/
/*  min-height: 290px !important;*/
/*}*/
/*.box-content.text-left.mission  p.stylish, .box-content.text-left.vision  p.stylish{*/
/*  min-height: 290px !important;*/
/*}*/


/*@media (max-width: 991px) {*/
/*  .box-content.mission, .box-content.vision{*/
/*    padding: 0 !important;*/
/*    min-height: unset !important;*/
/*  }*/
/*  .box-content.text-left.mission  p.stylish, .box-content.text-left.vision  p.stylish{*/
/*    min-height: unset !important;*/
/*  }*/

/*  .testi.testicool .owl-carousel .owl-nav{*/
/*    display: block !important;*/
/*  }*/
/*  .fa-arrow.ml-5{*/
/*    float: right !important;*/
/*    margin-left: 0 !important;*/
/*  }*/
/*  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:hover, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:focus, #header .header-nav-main.header-nav-main-mobile-dark nav > ul li a:active {*/
/*    background-color: unset !important;*/
/*  }*/
/*    !*.container {*!*/
/*    !*  max-width: 980px;*!*/
/*    !*}*!*/
/*  #header .header-nav-main.header-nav-main-mobile-dark:before {*/
/*    background-color: #1AA3BA;*/
/*  }*/
/*  #header .header-nav-main.header-nav-main-mobile-dark nav > ul li {*/
/*    border-bottom: unset;*/
/*    padding: 20px;*/
/*  }*/
/*  #header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a {*/
/*    color: #ffffff;*/
/*    font-size: 26px;*/
/*    font-weight: bold;*/
/*    text-transform: capitalize;*/
/*    text-align: center;*/
/*  }*/
/*  #header .header-nav-main nav {*/
/*    height: 100vh;*/
/*    max-height: 150vh;*/
/*    overflow: hidden;*/
/*    overflow-y: auto;*/
/*    padding: 0 15px;*/
/*    -webkit-transition: ease all 500ms;*/
/*    transition: ease all 500ms;*/
/*  }*/
/*  #section-concept{*/
/*    background-image: none !important;*/
/*  }*/
/*  .caption{*/
/*    padding: 5px 40px;*/
/*    text-align: center;*/
/*  }*/
/*  .caption h1{*/
/*    font-size: 30px !important;*/
/*  }*/
/*  .caption p{*/
/*    font-size: 14px !important;*/
/*  }*/
/*  .caption p img{*/
/*    width: 100px;*/
/*  }*/

/*  .feature-block div div h4{*/
/*    font-size: 22px;*/
/*    line-height: 1;*/
/*  }*/
/*  .feature-block div div p{*/
/*    font-size: 14px;*/
/*  }*/


/*  .phone {*/
/*    text-align: center;*/
/*    margin-top: -50px;*/
/*  }*/
/*  .phone img{*/
/*    height: auto;*/

/*  }*/
/*  h2.text-9{*/
/*    font-size: 30px !important;*/
/*    margin-top: 40px;*/
/*  }*/

/*  section.section {*/
/*    padding: 10px 0 !important;*/
/*  }*/
/*  h2.text-9{*/
/*    font-size: 30px !important;*/
/*    margin-top: 40px;*/
/*    font-weight: normal !important;*/
/*  }*/
/*  h5.font-weight-bold.text-9, h1.font-weight-bold.text-9 {*/
/*    font-size: 20px !important;*/
/*    !*text-align: left !important;*!*/
/*    !*font-weight: normal !important;*!*/
/*  }*/
/*  .download .row.align-items-center div{*/
/*    text-align: left;*/
/*  }*/
/*  .download .row.align-items-center div h2{*/
/*    font-size: 30px !important;*/
/*  }*/
/*  .download .row.align-items-center div p{*/
/*    font-size: 14px !important;*/
/*  }*/

/*  .process-step-content h4{*/
/*    font-size: 21px;*/
/*  }*/
/*  .process-step-content p{*/
/*    font-size: 15.4px;*/
/*  }*/


/*  .faq .row.align-items-center div h2 {*/
/*    font-size: 30px !important;*/
/*    margin-top: 30px;*/
/*  }*/
/*  .faq .row.align-items-center div.col-lg-4 p {*/
/*    font-size: 24px !important;*/
/*  }*/
/*  .toggle > label {*/
/*    font-size: 13px;*/
/*    font-weight: bold;*/
/*  }*/
/*  .toggle.rectangle  p {*/
/*    font-size: 16px !important;*/
/*  }*/

/*  .news{*/
/*    border-radius: 10px; padding: 10px;*/
/*  }*/
/*  .bitmap{*/
/*    position: absolute;*/
/*    top: 2px;*/
/*    right: 0;*/
/*  }*/

/*  .down div h2{*/
/*    font-size: 28px; line-height: 1.5;*/
/*    font-weight: bold;*/
/*    margin-top: 50px;*/
/*  }*/

/*  .down div p{*/
/*    font-size: 14px;*/
/*  }*/

/*  .store-buttons img{*/
/*    max-width: 45%;*/
/*  }*/

/*  .d-sm-none{*/
/*    display: none !important;*/
/*  }*/

/*  .d-lg-none{*/
/*    display: block !important;*/
/*  }*/
/*  .facility .owl-carousel .owl-nav {*/
/*    top: unset;*/
/*    bottom: 15px;*/
/*    left: 2%;*/
/*    width: 90%;*/
/*  }*/
/*  #program-offering {*/
/*    background-color: #FDF0E9;*/
/*  }*/
/*  .testimonial.testimonial-style-2{*/
/*    !*background-image: url(../img/control/testimonial-bg-phone.svg);*!*/
/*    !*background-size: cover; background-repeat: no-repeat; *!*/
/*    padding: 40px;*/
/*  }*/
/*  .testi .owl-carousel .owl-nav {*/
/*    width: 25%;*/
/*  }*/
/*  .events{*/
/*    background-image: url(../img/control/events-bg-phone.svg) !important;*/
/*    background-color: #FFC82A !important;*/
/*    background-size: cover !important;*/
/*    background-repeat: no-repeat !important;*/
/*    background-position: bottom !important;*/
/*    !*animation-duration: 750ms !important;*!*/
/*    !*animation-delay: 300ms !important;*!*/
/*    !*animation-fill-mode: forwards !important;*!*/
/*    height: auto;*/
/*  }*/



/*  .footer div.col-md-6{*/
/*    margin-bottom: 40px !important;*/
/*    text-align: left !important;*/
/*  }*/
/*  .footer div h5.text-3.mb-3{*/
/*    font-size: 18px !important;*/
/*  }*/
/*  .footer div p{*/
/*    font-size: 16px !important;*/
/*    margin-bottom: 10px !important;*/
/*    letter-spacing: 0.6px;*/
/*  }*/

/*  .box.rounded img{*/
/*    width: 100%;*/
/*  }*/
/*  .width-50{*/
/*    width: 50%;*/
/*  }*/


/*}*/

/*!*here*!*/

/*@media (max-width: 991px) {*/
/*  .d-sm-none{*/
/*    display: none !important;*/
/*  }*/
/*  .d-lg-none{*/
/*    display: block !important;*/
/*  }*/

/*  .footer div.col-md-6 {*/
/*    margin-bottom: 40px !important;*/
/*  }*/

/*  .footer div h5.text-3.mb-3 {*/
/*    font-size: 25px !important;*/
/*  }*/

/*  .footer div p {*/
/*    font-size: 16px !important;*/
/*    margin-bottom: 10px !important;*/
/*  }*/
/*  #section-concept{*/
/*    background-image: none !important;*/
/*  }*/

/*  !*.board{*!*/
/*  !*   padding: 30px 10px !important;*!*/
/*  !*   margin: 100px 20px !important;*!*/
/*  !*   background-image: url(../img/control/board_mobile_bg.svg) !important;*!*/
/*  !*   background-size: 70% 70% !important;*!*/
/*  !*   background-position: center !important;*!*/
/*  !*   background-repeat: no-repeat !important;*!*/
/*  !*}*!*/
/*  !*.board div h1{*!*/
/*  !*   font-size: 34px !important;*!*/
/*  !*}*!*/
/*  !*.board div p{*!*/
/*  !*   font-size: 16px !important;*!*/
/*  !*}*!*/

/*  #header .header-logo{*/
/*    text-align: center;*/
/*  }*/
/*  #header .header-logo img {*/
/*    -webkit-transition: all 0.3s ease;*/
/*    transition: all 0.3s ease;*/
/*    position: relative;*/
/*    top: 0;*/
/*    left: 45% !important;*/
/*    !*width: 130% !important;*!*/
/*  }*/
/*  .sign span{*/
/*    display: block !important;*/
/*  }*/

/*  html .bg-color-dark-scale-2{*/
/*    background-color: #1D75BC !important;*/
/*    background-image: none !important;*/
/*  }*/

/*}*/
/*!*@media (max-width: 370px) {*!*/
/*!*  .container {*!*/
/*!*    max-width: 360px;*!*/
/*!*  }*!*/
/*!*  .caption h1 {*!*/
/*!*    font-size: 40px !important;*!*/
/*!*  }*!*/
/*!*}*!*/

